import React, { useState, useEffect, useContext } from "react";
import { AuthContext } from "../../../contexts/AuthContext";
import {
  Col,
  Row,
  Form,
  Button,
  FormGroup,
  Label,
  Input,
  Spinner,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import axios from "axios";
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2";
import Header from "../../../layout/Header/Header";

import { v4 as uuidv4 } from "uuid";

import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
    },
  },
  button: {
    margin: theme.spacing(1),
  },
}));

function DevolucionesCreate() {
  const { user } = useContext(AuthContext);
  const URL_LOGS = process.env.REACT_APP_URL_LOGS;
  const URL_SURTIDOS_TELAS = process.env.REACT_APP_URL_SURTIDOS_TELAS;
  const URL_DEVOLUCIONES_TELAS = process.env.REACT_APP_URL_DEVOLUCIONES_TELAS;
  const URL_ARTICULOS_PEDIDO_TELAS = process.env.REACT_APP_URL_ARTICULOS_PEDIDO_TELAS;
  const URL_INVENTARIOS_TELAS = process.env.REACT_APP_URL_INVENTARIOS_TELAS;
  const URL_DEVOLUCIONES_ACUMULADOS_TELAS = process.env.REACT_APP_URL_DEVOLUCIONES_ACUMULADOS_TELAS;

  const [surtidos, setSurtidos] = useState([]);
  const [selectedSurtido, setSelectedSurtido] = useState("");

  const [cliente, setCliente] = useState("");
  const [idCliente, setIdCliente] = useState("");
  const [fecha, setFecha] = useState("");
  const [fecha_compromiso, setFechaCompromiso] = useState("");
  const [observaciones, setObservaciones] = useState("NA");
  const [total_kilos, setTotalKilos] = useState(0);
  const [total_metros, setTotalMetros] = useState(0);

  const [pedido, setPedido] = useState("");

  const [articulosPedido, setArticulosPedido] = useState([]);
  const [articulosDevolucion, setArticulosDevolucion] = useState([]);
  const [fecha_surtido, setFechaSurtido] = useState("");
  const [clientePedido, setClientePedido] = useState("");
  const [total_general, setTotalGeneral] = useState(0);
  const [impuestos, setImpuestos] = useState(0);
  const [iva, setIva] = useState(0);
  const [subTotal, setSubTotal] = useState(0);
  const [total_kilos_pedido, setTotalKilosPedido] = useState(0);
  const [total_metros_pedido, setTotalMetrosPedido] = useState(0);
  const [total_general_pedido, setTotalGeneralPedido] = useState(0);
  const [rollosTotales, setRollosTotales] = useState(0);

  const [idsInventario, setIdsInventario] = useState([]);
  const [cantidadAgrupado, setCantidadAgrupado] = useState([]);


  const [modalProgreso, setModalProgreso] = useState(false);
  const toggleProgreso = () => setModalProgreso(!modalProgreso);

  const classes = useStyles();
  const [inputFields, setInputFields] = useState([
    {
      id: uuidv4(),
      idInventario: "",
      telas: "",
      idTela: "",
      unidad: "",
      colores: "",
      cantidad: 0,
      clave: "",
      articulosPedido: "",
      venta: 0,
      precio:0
    },
  ]);

  const [validaBoton, setValidaBoton] = useState(true);

  useEffect(() => {
    axios
      .get(URL_SURTIDOS_TELAS, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allSurtidos = res.data;
        setSurtidos(allSurtidos);
      })
      .catch((err) => {
        console.log(err);
      });

  }, []);

  function calculaDevolucion() {
    const newArticulosPedido = articulosPedido.map((AP) => {
      cantidadAgrupado.map((c) => {
        if (
          AP.telas[0]._id == c.idTela &&
          AP.colores[0]._id == c.colores
        ) {
          AP.surtido = parseFloat(AP.surtido) - parseFloat(c.cantidad);
          AP.pendiente_surtir =
            parseFloat(AP.pendiente_surtir) + parseFloat(c.cantidad);
        }
        return AP;
      });
    });
    setArticulosPedido(newArticulosPedido);
  }


  const saveDevolucion = async (event) => {
    event.preventDefault();
    setValidaBoton(false);
    toggleProgreso();

    calculaDevolucion();

    let totalRollos = inputFields.length + cantidadAgrupado.length;
    // + articulosPedido.length
    try {
      await axios
        .post(
          URL_DEVOLUCIONES_TELAS,
          {
            fecha,
            surtidosTelas: selectedSurtido,
            user: user.id,
            observaciones,
            aplicada: "No",
            total_cantidad: total_kilos + total_metros,
            clientes: idCliente,
            subTotal:subTotal.toFixed(2),
            iva: iva.toFixed(2),
            total_general:total_general.toFixed(2),
            impuestos

          },
          {
            headers: {
              Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
            },
          }
        )
        .then((data) => {
          inputFields.map((a) => {
            if (a.telas != "") {
              axios
                .patch(
                  `${URL_INVENTARIOS_TELAS}/devolucion/${a.idInventario}`,
                  {
                    devolucionesTelas: data.data._id,
                    vendido: "No",
                    telas:a.idTela,
                    colores:a.colores,
                    clave:a.clave,
                    precio: a.precio,
                    surtidosTelas: selectedSurtido,
                  },
                  {
                    headers: {
                      Authorization: `Bearer: ${localStorage.getItem(
                        "app_token"
                      )}`,
                    },
                  }
                )
                .then(() => {
                  totalRollos = totalRollos - 1;
                  if (totalRollos == 0) {
                    axios
                      .post(
                        URL_LOGS,
                        {
                          tipo: "Crear Devolucion",
                          detalle: `Surtido ${selectedSurtido}`,
                          user: user.id,
                        },
                        {
                          headers: {
                            Authorization: `Bearer: ${localStorage.getItem(
                              "app_token"
                            )}`,
                          },
                        }
                      )
                      .then(() => {
                        Swal.fire("Good job!", "Creado con exito", "success");
                        setTimeout(() => {
                          window.location.reload();
                        }, 1000);
                      })
                      .catch((error) => {
                        Swal.fire({
                          icon: "error",
                          title: "Oops...",
                          text: "Something went wrong!",
                          footer: `${error.response.data}`,
                        });
                        console.log(error);
                        setValidaBoton(true);
                      });
                  }
                });
            } else {
              totalRollos = totalRollos - 1;
              if (totalRollos == 0) {
                axios
                  .post(
                    URL_LOGS,
                    {
                      tipo: "Crear Devolucion",
                      detalle: `Surtido ${selectedSurtido}`,
                      user: user.id,
                    },
                    {
                      headers: {
                        Authorization: `Bearer: ${localStorage.getItem(
                          "app_token"
                        )}`,
                      },
                    }
                  )
                  .then(() => {
                    Swal.fire("Good job!", "Creado con exito", "success");
                    setTimeout(() => {
                      window.location.reload();
                    }, 1000);
                  })
                  .catch((error) => {
                    Swal.fire({
                      icon: "error",
                      title: "Oops...",
                      text: "Something went wrong!",
                      footer: `${error.response.data}`,
                    });
                    console.log(error);
                    setValidaBoton(true);
                  });
              }
            }
          });



          // Pegamos a Devoluciones Acumulado

          cantidadAgrupado.map((w) => {
            axios
              .post(
                `${URL_DEVOLUCIONES_ACUMULADOS_TELAS}`,
                {
                  devolucionesTelas: data.data._id,
                  telas: w.idTela,
                  articulosPedidoTelas: w.articulosPedido,
                  colores: w.colores,
                  cantidad: w.cantidad,
                  pedidosTelas: pedido,
                  fecha,
                  clientes: idCliente,
                  surtidosTelas: selectedSurtido,
                  clave: w.clave,
                  venta: w.venta,
                },
                {
                  headers: {
                    Authorization: `Bearer: ${localStorage.getItem(
                      "app_token"
                    )}`,
                  },
                }
              )
              .then(() => {
                totalRollos = totalRollos - 1;
                if (totalRollos == 0) {
                  axios
                    .post(
                      URL_LOGS,
                      {
                        tipo: "Crear Devolucion",
                        detalle: `Surtido ${selectedSurtido}`,
                        user: user.id,
                      },
                      {
                        headers: {
                          Authorization: `Bearer: ${localStorage.getItem(
                            "app_token"
                          )}`,
                        },
                      }
                    )
                    .then(() => {
                      Swal.fire("Good job!", "Creado con exito", "success");
                      setTimeout(() => {
                        window.location.reload();
                      }, 1000);
                    })
                    .catch((error) => {
                      Swal.fire({
                        icon: "error",
                        title: "Oops...",
                        text: "Something went wrong!",
                        footer: `${error.response.data}`,
                      });
                      console.log(error);
                      setValidaBoton(true);
                    });
                }
              })
              .catch((error) => {
                Swal.fire({
                  icon: "error",
                  title: "Oops...",
                  text: "Something went wrong!",
                  footer: `${error.response.data}`,
                });
                console.log(error);
              });
          });

          // Terminamos de post a devoluciones acumulado
        });
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Something went wrong!",
        footer: `${error.response.data}`,
      });
      console.log(error);
    }
  };

  const handleAddFields = () => {
    setInputFields([
      ...inputFields,
      {
        id: uuidv4(),
        idInventario: "",
        telas: "",
        idTela: "",
        unidad: "",
        colores: "",
        cantidad: 0,
        clave: "",
        articulosPedido: "",
        venta: 0,
        precio:0
      },
    ]);
  };

  const handleRemoveFields = (id) => {
    const values = [...inputFields];
    values.splice(
      values.findIndex((value) => value.id === id),
      1
    );
    setInputFields(values);
    let mts = values.filter((e) => e.unidad == "Metros");
    let totalMts = mts.map((c) => parseFloat(c.cantidad));
    let TM = totalMts.reduce((t, total, index) => t + total, 0);
    setTotalMetros(TM);
    let KG = values.filter((e) => e.unidad == "Kilos");
    let totalKG = KG.map((c) => parseFloat(c.cantidad));
    let TK = totalKG.reduce((t, total, index) => t + total, 0);
    setTotalKilos(TK);
  };

  function BuscaArticulo(id, event) {
    if (event.target.value.length >= 24) {
      if (idsInventario.includes(event.target.value)) {
        if (
          inputFields.filter((e) => e.idInventario == event.target.value)
            .length > 0
        ) {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "El rollo ya fue escaneado!",
            showConfirmButton: false,
          });
          const newInputFields = inputFields.map((i) => {
            if (id === i.id) {
              i.idInventario = "";
            }
            return i;
          });
          setInputFields(newInputFields);
        } else {
          articulosDevolucion.map((a) => {
            if (a._id == event.target.value) {
              let articulosDevolucion = a._id;
              let telas = a.telas;
              let idTela = a.idTela;
              let unidadTela = a.unidadTela;
              let color = a.color;
              let cantidad = a.cantidad;
              let clave = a.clave;
              let articulosPedido = a.articulosPedido;
              let precio = a.precio;
              let venta= a.venta
              handleChangeInputArticulo(
                id,
                articulosDevolucion,
                telas,
                idTela,
                unidadTela,
                color,
                cantidad,
                clave,
                articulosPedido,
                precio,
                venta
              );
            }
          });
        }
      } else {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "El rollo no pertenece al pedido o fue devuelto previamente!",
          showConfirmButton: false,
        });
        const newInputFields = inputFields.map((i) => {
          if (id === i.id) {
            i.idInventario = "";
          }
          return i;
        });
        setInputFields(newInputFields);
      }
    } else {
      const newInputFields = inputFields.map((i) => {
        if (id === i.id) {
          i.idInventario = event.target.value;
        }
        return i;
      });
      setInputFields(newInputFields);
    }
  }

  const handleChangeInputArticulo = (
    id,
    articulosDevolucion,
    telas,
    idTela,
    unidadTela,
    color,
    cantidad,
    clave,
    articulosPedido,
    precio,
    venta
  ) => {
    const newInputFields = inputFields.map((i) => {
      if (id === i.id) {
        i.idInventario = articulosDevolucion;
        i.telas = telas;
        i.idTela = idTela;
        i.unidad = unidadTela;
        i.colores = color;
        i.cantidad = cantidad;
        i.clave = clave;
        i.articulosPedido = articulosPedido;
        i.venta = venta;
        i.precio = precio;
      }
      return i;
    });
    setInputFields(newInputFields);
    ActualizaTotales();
    handleAddFields();
  };

  function ActualizaTotales() {
    let mts = inputFields.filter((e) => e.unidad == "Metros");
    let totalMts = mts.map((c) => parseFloat(c.cantidad));
    let TM = totalMts.reduce((t, total, index) => t + total, 0);
    setTotalMetros(TM);
    let KG = inputFields.filter((e) => e.unidad == "Kilos");
    let totalKG = KG.map((c) => parseFloat(c.cantidad));
    let TK = totalKG.reduce((t, total, index) => t + total, 0);
    setTotalKilos(TK);
    let totalDinero = inputFields.map((c) => parseFloat(c.venta));
    let dinero = totalDinero.reduce((t, total, index) => t + total, 0);
    setSubTotal(dinero);
    if (impuestos != 0) {
      setIva((impuestos * dinero) / 100);
    } else {
      setIva(0);
    }
    let totTemp = (impuestos * dinero) / 100 + dinero;

    setTotalGeneral(totTemp);

    let agrupado = inputFields.reduce(function (groups, item) {
      const val = item["idTela"] + item["colores"] + item["clave"];
      groups[val] = groups[val] || {
        idTela: item.idTela,
        colores: item.colores,
        clave: item.clave,
        cantidad: 0,
        venta: 0,
      };
      groups[val].cantidad += item.cantidad;
      groups[val].venta += item.venta;
      groups[val].id = item.id;
      groups[val].idInventario = item.idInventario;
      groups[val].telas = item.telas;
      groups[val].idTela = item.idTela;
      groups[val].unidad = item.unidad;
      groups[val].colores = item.colores;
      groups[val].clave = item.clave;
      groups[val].articulosPedido = item.articulosPedido;
      return groups;
    }, []);

    let CA = Object.values(agrupado);
    setCantidadAgrupado(CA);

    let tr = 0;
    inputFields.map((a) => {
      if (a.telas != "") {
        return (tr = tr + 1);
      }
    });
    setRollosTotales(tr);
  }

  function jalaSurtido(e) {
    setSelectedSurtido(e);

    axios
      .get(`${URL_INVENTARIOS_TELAS}/surtidosDevolucion/${e}`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allArticulosDevolucion = res.data;
        setArticulosDevolucion(allArticulosDevolucion);
        let result = allArticulosDevolucion.map(({ _id }) => _id);
        setIdsInventario(result);
      })
      .catch((err) => {
        console.log(err);
      });

    axios
      .get(`${URL_ARTICULOS_PEDIDO_TELAS}/pedidosDevolucion/${e}`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allArticulosPedido = res.data;
        setArticulosPedido(allArticulosPedido);
      })
      .catch((err) => {
        console.log(err);
      });

    surtidos.map((a) => {
      if (a._id == e) {
        setFechaSurtido(a.fecha);
        setTotalKilosPedido(a.total_kilos);
        setTotalMetrosPedido(a.total_metros);
        setTotalGeneralPedido(a.total_general);
        setCliente(a.pedidosTelas[0].clientes[0].razon_social);
        setIdCliente(a.pedidosTelas[0].clientes[0]._id);
        setPedido(a.pedidosTelas[0]._id);
        setImpuestos(a.impuestos);
      }
    });
  }


  return (
    <>
      <Header />
      <br />
      <br />
      {user.pedidos_create ? (
        <div className="card container col-10">
          <h3 align="center">Nueva Devolucion</h3>
          <Form>
            <Row>
              <Col md={3}>
                <Label className="mr-sm-2">Surtido</Label>
                <Input
                  className="col-sm-12"
                  type="select"
                  value={selectedSurtido}
                  required
                  onChange={(e) => jalaSurtido(e.target.value)}
                >
                  <option value="0">Selecciona un Surtido</option>
                  {surtidos
                    .sort((a, b) => (a.idSurtido < b.idSurtido ? 1 : -1))
                    .map((a) => {
                      return (
                        <option value={a._id}>
                          # {a.idSurtidoTelas}{" "}
                          {a.pedidosTelas[0].clientes[0].nombre_comercial}
                        </option>
                      );
                    })}
                </Input>
              </Col>
              <Col md={3}>
                <Label>Fecha Surtido</Label>
                <Input
                  type="date"
                  placeholder="Fecha"
                  value={fecha_surtido}
                  required
                  disabled
                  onChange={(e) => {
                    setFechaSurtido(e.target.value);
                  }}
                />
              </Col>
              <Col md={3}>
                <Label>Cliente</Label>
                <Input
                  type="text"
                  placeholder="Cliente"
                  value={cliente}
                  required
                  disabled
                  onChange={(e) => {
                    setCliente(e.target.value);
                  }}
                />
              </Col>
            </Row>
            <Row>
              <Col md={3}>
                <Label>Fecha</Label>
                <Input
                  type="date"
                  placeholder="Fecha"
                  value={fecha}
                  required
                  onChange={(e) => {
                    setFecha(e.target.value);
                  }}
                />
              </Col>
              <Col md={6}>
                <Label for="exampleEmail" className="mr-sm-2">
                  Observaciones
                </Label>
                <Input
                  type="text"
                  placeholder="Observaciones"
                  value={observaciones}
                  required
                  onChange={(e) => {
                    setObservaciones(e.target.value);
                  }}
                />
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <h4 id="logoutBoton">
                  TOTALES {new Intl.NumberFormat("en-US").format(total_kilos)}{" "}
                  kg. / {new Intl.NumberFormat("en-US").format(total_metros)}{" "}
                  mts. / {rollosTotales} Rollos
                </h4>
              </Col>
            </Row>

            {/* Tabla Articulos */}

            <Row>
              <Col md={3}>
                <Label className="mr-sm-2">Codigo</Label>
              </Col>
              <Col md={4}>
                <Label className="mr-sm-2">Articulos</Label>
              </Col>
              <Col md={1}>
                <Label className="mr-sm-2">Unidad</Label>
              </Col>
              <Col md={1}>
                <Label className="mr-sm-2">Cantidad</Label>
              </Col>
              <Col md={1}>
                <Label className="mr-sm-2">Clave</Label>
              </Col>
            </Row>

            {inputFields.map((inputField) => (
              <div key={inputField.id}>
                <Row>
                  <Col md={3}>
                    <Input
                      bsSize="sm"
                      name="idInventario"
                      type="string"
                      value={inputField.idInventario}
                      onChange={(event) => {
                        BuscaArticulo(inputField.id, event);
                      }}
                    ></Input>
                  </Col>
                  <Col md={4}>
                    <Input
                      bsSize="sm"
                      name="telas"
                      type="string"
                      value={inputField.telas}
                      disabled
                    ></Input>
                  </Col>
                  <Col md={1}>
                    <Input
                      bsSize="sm"
                      name="unidad"
                      type="string"
                      value={inputField.unidad}
                      disabled
                    ></Input>
                  </Col>

                  <Col md={1}>
                    <Input
                      bsSize="sm"
                      name="cantidad"
                      type="string"
                      value={inputField.cantidad}
                      disabled
                    ></Input>
                  </Col>

                  <Col md={1}>
                    <Input
                      bsSize="sm"
                      name="clave"
                      type="string"
                      value={inputField.clave}
                      disabled
                    ></Input>
                  </Col>

                  <Col>
                    <Button
                      size="sm"
                      className="btn"
                      color="danger"
                      disabled={inputFields.length === 1}
                      onClick={() => handleRemoveFields(inputField.id)}
                      tabindex="-1"
                    >
                      <i class="fas fa-minus"></i>
                    </Button>
                    <Button
                      size="sm"
                      className="btn"
                      color="info"
                      onClick={handleAddFields}
                      tabindex="-1"
                    >
                      <i class="fas fa-plus"></i>
                    </Button>
                  </Col>
                </Row>
              </div>
            ))}
            <Row>
              <Col md={12}>
                <h4 id="logoutBoton">
                  TOTALES {new Intl.NumberFormat("en-US").format(total_kilos)}{" "}
                  kg. / {new Intl.NumberFormat("en-US").format(total_metros)}{" "}
                  mts. / {rollosTotales} Rollos
                </h4>
              </Col>
            </Row>
            {/* Termina Tabla Articulos */}

            <br />
          </Form>
          <Row>
            <Col>
              {validaBoton ? (
                <Button
                  type="submit"
                  className="btn btn-success"
                  onClick={saveDevolucion}
                >
                  Guardar
                </Button>
              ) : (
                <Button type="submit" className="btn btn-success" disabled>
                  Guardar
                </Button>
              )}
              <Button
                href="/ListadoDevoluciones"
                className="btn btn-danger"
                id="botonListado"
              >
                Regresar
              </Button>
            </Col>
          </Row>
          <br />
           <Modal size="sm" isOpen={modalProgreso} toggle={toggleProgreso}>
            <ModalHeader toggle={toggleProgreso}>
              <h4>Progreso</h4>
            </ModalHeader>
            <ModalBody>
             <h4> Este proceso puede tardar varios minutos.
              <br />
              Por favor no cierre ni refresque su navegador.
              </h4>
              <br />
              <div className="progreso">
                  <Spinner
                    style={{
                      width: "50px",
                      height: "50px",
                      color: "#232B41",
                    }}
                  />
                  <Spinner
                    style={{
                      width: "50px",
                      height: "50px",
                      color: "#232B41",
                    }}
                  />
                  <Spinner
                    style={{
                      width: "50px",
                      height: "50px",
                      color: "#232B41",
                    }}
                  />
              </div>
            </ModalBody>
          </Modal>
        </div>
      ) : undefined}
    </>
  );
}

export default DevolucionesCreate;
