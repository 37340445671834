import React, { useContext } from "react";
import { AuthContext } from '../../contexts/AuthContext'
import { Col, Row, Button } from "reactstrap";
import Footer from "../../layout/Footer/Footer";
import Header from "../../layout/Header/Header";

function BotonesCompras() {
  const { user } = useContext(AuthContext);
  return (
    <>
          <Header />
          {user.menu_ventas ?(
      <div className="container">
        <br />
        <br />
        <Row >
       
        <Col md={4} align="center">
          {user.menu_pedidos ?(
            <Button href="/ListadoPedidos" className="botonesMenu" color="success">
              <i class="fas fa-shopping-basket fa-7x"></i>
              <br />
              <br />
              Pedidos
            </Button>
          ): <Button disabled className="botonesMenu" color="success">
          <i class="fas fa-shopping-basket fa-7x"></i>
          <br />
          <br />
          Pedidos
        </Button> }
          </Col>
          <Col md={4} align="center">
          {user.menu_surtidos ?(
            <Button href="/ListadoApartados" className="botonesMenu" color="success">
              <i class="fas fa-people-carry fa-7x"></i>
              <br />
              <br />
              Apartados
            </Button>
          ): <Button disabled className="botonesMenu" color="success">
          <i class="fas fa-people-carry fa-7x"></i>
          <br />
          <br />
          Apartados
        </Button> }
          </Col>
          <Col md={4} align="center">
          {user.menu_surtidos ?(
            <Button href="/ListadoSurtidos" className="botonesMenu" color="success">
              <i class="fas fa-truck-moving fa-7x"></i>
              <br />
              <br />
              Surtidos
            </Button>
          ): <Button disabled className="botonesMenu" color="success">
          <i class="fas fa-truck-moving fa-7x"></i>
          <br />
          <br />
          Surtidos
        </Button> }
          </Col>
          </Row>
          <br />
          <Row>
          <Col md={4} align="center">
          {user.menu_devoluciones ?(
            <Button href="/ListadoDevoluciones" className="botonesMenu" color="success">
              <i class="fas fa-undo-alt fa-7x"></i>
              <br />
              <br />
              Devoluciones
            </Button>
          ): <Button disabled className="botonesMenu" color="success">
          <i class="fas fa-undo-alt fa-7x"></i>
          <br />
          <br />
          Devoluciones
        </Button> }
          </Col>
        </Row>
      </div>
      ): undefined } 
      
    </>
  );
}

export default BotonesCompras;
